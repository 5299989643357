//react libraries import
import React from 'react';

//custom libraries import

const FacilitiesPage = () => {
    return (
        <main>
            <div className="my-12">
                <p className="text-center text-3xl font-bold text-primary uppercase">Infrastructure & Facilities</p>
                <div className="w-11/12 md:w-3/4 bg-extra-light-primary rounded p-4 md:p-8 my-12 mx-4 md:mx-auto">
                    <div className="my-6">
                        <p className="text-blue text-xl border-b-4 border-third max-w-max">Sports Facilities</p>
                        <div className="flex flex-col md:flex-row items-start md:items-center mt-4">
                            <div className="inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e49d22">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <p className="ml-2 mr-8">Football Ground</p>
                            </div>
                            <div className="inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e49d22">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <p className="ml-2 mr-8">Kabbadi Ground</p>
                            </div>
                            <div className="inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e49d22">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <p className="ml-2 mr-8">Volleyball Ground</p>
                            </div>
                        </div>
                    </div>
                    <div className="my-16">
                        <p className="text-blue text-xl border-b-4 border-third max-w-max">Education Facilities</p>
                        <div className="flex flex-col md:flex-row items-start md:items-center mt-4">
                            <div className="inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e49d22">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <p className="ml-2 mr-8">Library</p>
                            </div>
                            <div className="inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e49d22">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <p className="ml-2 mr-8">Well Equiped Classrooms</p>
                            </div>
                        </div>
                    </div>
                    <div className="my-16">
                        <p className="text-blue text-xl border-b-4 border-third max-w-max">Accessibility Facilities</p>
                        <div className="flex flex-col md:flex-row items-start md:items-center mt-4">
                            <div className="inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e49d22">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <p className="ml-2 mr-8">Ramped or Level Entrances</p>
                            </div>
                            <div className="inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e49d22">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <p className="ml-2 mr-8">Accessible Washrooms</p>
                            </div>
                        </div>
                    </div>
                    <div className="my-8">
                        <p className="text-blue text-xl border-b-4 border-third max-w-max">Other Infra Facilities</p>
                        <div className="flex flex-col md:flex-row items-start md:items-center mt-4">
                            <div className="inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e49d22">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <p className="ml-2 mr-8">Kids Play Area</p>
                            </div>
                            <div className="inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e49d22">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <p className="ml-2 mr-8">Auditorium</p>
                            </div>
                            <div className="inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e49d22">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <p className="ml-2 mr-8">Activity Center</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default FacilitiesPage;